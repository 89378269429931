@charset "UTF-8";
@font-face { font-family: "Helvetica Neue"; src: local("Helvetica Neue"); unicode-range: U+0020; }

@font-face { font-family: "Arial"; src: local("Arial"); unicode-range: U+0020; }

/* noto-sans-kr-regular - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 400; src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* noto-sans-kr-500 - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 500; src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* noto-sans-kr-700 - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 700; src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-regular - latin */
@font-face { font-family: "Roboto"; font-style: normal; font-weight: 400; src: local("Roboto"), local("Roboto-Regular"), url("/css/fonts/roboto-v20-latin-regular.woff2") format("woff2"), url("/css/fonts/roboto-v20-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-700 - latin */
@font-face { font-family: "Roboto"; font-style: normal; font-weight: 700; src: local("Roboto Bold"), local("Roboto-Bold"), url("/css/fonts/roboto-v20-latin-700.woff2") format("woff2"), url("/css/fonts/roboto-v20-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

@font-face { font-family: "11StreetGothic"; font-style: normal; font-weight: 400; src: url("/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff"); }

@font-face { font-family: "11StreetGothic"; src: url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff"); font-style: normal; font-weight: 700; }

@font-face { font-family: "11StreetGothicBold"; font-weight: bold; src: url("/css/fonts/11STREET_Gothic_bold.eot"); src: url("/css/fonts/11STREET_Gothic_bold.woff") format("woff2"), url("/css/fonts/11STREET_Gothic_bold.woff") format("woff"), url("/css/fonts/11STREET_Gothic_bold.ttf") format("truetype"); }

/* new lato-regular - latin */
@font-face { font-family: "Lato New"; font-style: normal; font-weight: 400; src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-regular.woff2") format("woff2"), url("/css/fonts/lato-v23-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; }

/* new lato-700 - latin */
@font-face { font-family: "Lato New"; font-style: normal; font-weight: 700; src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-700.woff2") format("woff2"), url("/css/fonts/lato-v23-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; }

/* new lato-900 - latin */
@font-face { font-family: "Lato New"; font-style: normal; font-weight: 900; src: local("Lato Black"), local("Lato-Black"), url("/css/fonts/lato-v23-latin-900.woff2") format("woff2"), url("/css/fonts/lato-v23-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; }

*, *:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, select, textarea, button { margin: 0; padding: 0; }

html { overflow-y: auto; width: 100%; height: 100%; background: #eee; }

body { width: 100%; height: 100%; }

dl, ul, ol, menu, li { list-style: none; }

img, fieldset, iframe { border: 0 none; }

img { vertical-align: top; }

input, select, textarea, button { vertical-align: middle; }

input::-ms-clear { display: none; }

button { border: 0 none; background-color: transparent; cursor: pointer; border-radius: 0; box-sizing: border-box; }

table { border-collapse: collapse; border-spacing: 0; empty-cells: show; }

caption, legend { position: absolute; top: -9999px; left: -9999px; font-size: 0px; line-height: 0; }

a { color: #666; text-decoration: none; }

a:hover { text-decoration: underline; }

a:active { background-color: transparent; }

body, h1, h2, h3, h4, th, td, input, select, textarea, button { font-size: 14px; line-height: 1.5; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; letter-spacing: -1px; }

hr { display: none; }

address, caption, cite, code, dfn, em, var { font-style: normal; font-weight: normal; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after { content: ""; content: none; }

q:before, q:after { content: ""; content: none; }

/* COMMON */
.is-form__text-2, .is-form__text-3 { width: 100%; height: 26px; background-color: transparent; border: 1px solid #0b83e6; color: #111; }

.is-form__text-2.warning, .is-form__text-3.warning { border: solid 1px #f43142; }

.is-form__text-3 { height: 28px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.is-btn__icon { background-color: transparent; border: none; }

.is-icon { overflow: hidden; display: inline-block; width: 20px; height: 20px; vertical-align: middle; color: transparent; line-height: 200px; font-size: 0; }

.is-icon__refresh { margin: 9px; background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -292px -32px; width: 22px; height: 22px; }

.is-btn__file-upload:before { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -258px -170px; width: 24px; height: 24px; }

.is-btn__file-download:before { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -200px -168px; width: 24px; height: 24px; }

.is-btn__file-folder-new:before { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -258px 0px; width: 24px; height: 24px; }

.is-btn__file-move:before { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -258px -34px; width: 24px; height: 24px; }

.is-btn__file-delete:before { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -258px -136px; width: 24px; height: 24px; }

.is-btn__file-trash-all:before { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -258px -102px; width: 24px; height: 24px; }

.is-btn__file-restore:before { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -258px -68px; width: 24px; height: 24px; }

.is-icon__view-img { margin: 1px; background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -154px -168px; width: 36px; height: 36px; }

.is-icon__view-list { position: relative; width: 38px; height: 38px; }

.is-icon__view-list:before { display: block; width: 14px; height: 13px; margin: 12px 12px 12px; border-top: 1px solid #333; border-bottom: 1px solid #333; content: ""; }

.is-icon__view-list:after { position: absolute; top: 50%; left: 50%; width: 14px; height: 1px; margin: -1px 0 0 -7px; background-color: #333; content: ""; }

.is-icon__search { margin: 8px; background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -292px -64px; width: 22px; height: 22px; }

.is-icon__shortcut { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -292px -128px; width: 22px; height: 22px; }

.is-btn__icon.active .is-icon__shortcut { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -292px -96px; width: 22px; height: 22px; }

.is-icon__folder { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -34px -258px; width: 24px; height: 24px; }

.is-icon__list-folder { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -136px -258px; width: 24px; height: 24px; }

.is-icon__list-folder-upper { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -102px -258px; width: 24px; height: 24px; }

.is-icon__list-img { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -170px -258px; width: 24px; height: 24px; }

.is-icon__list-file { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -68px -258px; width: 24px; height: 24px; }

.is-icon__list2-folder { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: 0px -168px; width: 80px; height: 80px; }

.is-icon__list2-folder-upper { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -168px 0px; width: 80px; height: 80px; }

.is-icon__list2-file { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: 0px 0px; width: 158px; height: 158px; }

.is-icon__list2-loading { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -168px -90px; width: 54px; height: 54px; }

.is-table { width: 100%; background-color: #fff; border-collapse: collapse; table-layout: fixed; }

.is-table .td_center { text-align: center; }

.is-table thead th, .is-table thead td { padding: 0 4px; height: 44px; background-color: #fafafa; text-align: left; font-weight: normal; font-size: 14px; color: #333; }

.is-table tbody tr:hover, .is-table tbody tr.selected { background-color: #fafafa; }

.is-table tbody tr.tr_info { background-color: inherit; }

.is-table tbody tr.tr_info td { padding: 0; }

.is-table tbody tr th, .is-table tbody tr td { padding: 9px 4px; font-size: 14px; color: #111; text-align: left; vertical-align: top; line-height: 30px; }

.is-table tbody tr:first-of-type th, .is-table tbody tr:first-of-type td { position: relative; padding-top: 14px; }

.is-table tbody tr:first-of-type th:before, .is-table tbody tr:first-of-type td:before { position: absolute; top: 0; left: 0; right: 0; height: 10px; background-color: #fff; content: ""; }

.is-table thead th.td_check .check-style__5 { margin-top: 0; }

.is-table th.td_check, .is-table td.td_check { padding-left: 11px; }

.is-table th.td_check .check-style__5, .is-table td.td_check .check-style__5 { margin: -10px 0 -5px; }

.is-table th.td_title, .is-table td.td_title { padding-left: 20px; }

.is-table th.td_date, .is-table td.td_date { color: #999; }

.is-table th.td_info, .is-table td.td_info { color: #666; }

.is-table th.td_info2, .is-table td.td_info2 { color: #111; }

.is-table th.td_state .state_waiting, .is-table th.td_state .state_skip, .is-table td.td_state .state_waiting, .is-table td.td_state .state_skip { color: #999; }

.is-table th.td_state .state_success, .is-table td.td_state .state_success { color: #0b83e6; }

.is-table th.td_state .state_warning, .is-table td.td_state .state_warning { color: #f43142; }

.is-table th.td_file, .is-table td.td_file { padding-left: 20px; }

.is-table th.td_file .file_set, .is-table td.td_file .file_set { display: block; max-width: 320px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.is-table th.td_file .is-icon, .is-table td.td_file .is-icon { margin: -3px 12px 0 0; }

.is-table th.td_file .is-icon__list-lower, .is-table td.td_file .is-icon__list-lower { position: relative; margin-right: 4px; width: 24px; height: 24px; }

.is-table th.td_file .is-icon__list-lower:before, .is-table td.td_file .is-icon__list-lower:before { position: absolute; top: 7px; left: 7px; width: 10px; height: 10px; border: 1px solid #ccc; border-width: 0 0 1px 1px; content: ""; }

.is-table th .is-btn__icon, .is-table td .is-btn__icon { vertical-align: 2px; }

.is-table__fixed { overflow: hidden; position: relative; height: 320px; padding-top: 44px; background-color: #fafafa; border: 1px solid #eee; zoom: 1; }

.is-table__fixed .in_scroll { overflow-x: hidden; overflow-y: auto; height: 100%; background-color: #fff; zoom: 1; }

.is-table__fixed .is-table { border-collapse: separate; border-spacing: 0; }

.is-table__fixed .is-table th, .is-table__fixed .is-table td { border: none; }

.is-table__fixed .is-table thead th { padding: 0px; height: 0px; background-color: #fafafa; border: none; }

.is-table__fixed .is-table thead th.td_file { padding-left: 20px; }

.is-table__fixed .is-table thead th .th-text { position: absolute; top: 0; width: inherit; padding: 11px 0 12px; background-color: #fafafa; }

/* LAYOUT */
.is-header { display: table; width: 100%; padding: 17px 24px 20px; border: 1px solid #e0e0e0; background: #fff; box-sizing: border-box; }

.is-header .is-header__title { display: table-cell; vertical-align: middle; }

.is-header .is-header__title .title { color: #333; font-size: 24px; }

.is-header .is-header__title .text_sub { margin-top: 3px; color: #666; font-size: 13px; }

.is-header .is-header__title .link_info { margin-left: 6px; padding: 0 2px; vertical-align: 0; color: #0b83e6; font-size: 13px; }

.is-header .is-header__title .link_info:after { display: inline-block; height: 6px; width: 6px; border: 1px solid #0b83e6; border-width: 0 1px 1px 0; vertical-align: 2px; transform: rotate(-45deg); content: ""; }

.is-header .is-header__amount { display: table-cell; width: 200px; text-align: center; vertical-align: middle; }

.is-header .is-header__amount .amount_text { color: #333; }

.is-header .is-header__amount .amount_text > i { color: #0b83e6; font-style: normal; }

.is-header .is-header__amount .amount_bar { margin-top: 10px; background-color: #dddddd; border: solid 1px #d8d8d8; border-radius: 3.5px; }

.is-header .is-header__amount .amount_bar .bar_rate { overflow: hidden; display: block; height: 5px; margin: -1px 0; background-color: #0b83e6; border-radius: 3.5px; color: transparent; font-size: 0; line-height: 999px; }

.is-body { position: relative; border: 1px solid #e0e0e0; background: #fff; zoom: 1; }

.is-body:after { display: block; content: ""; clear: both; }

.is-header + .is-body { border-top-color: #eee; margin-top: -1px; }

.is-body .is-tree { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: none; width: 220px; min-height: 980px; padding-bottom: 52px; vertical-align: top; border-right: 1px solid #eee; }

.is-body .is-detail { position: relative; min-height: 980px; }

.is-body .is-detail .is-btn__toggle { position: absolute; z-index: 2; top: -1px; left: -1px; width: 26px; height: 52px; background-color: #fff; border: 1px solid #eee; }

.is-body .is-detail .is-btn__toggle .is-icon__toggle { height: 8px; width: 8px; margin: -4px 0 0 -2px; border: 1px solid #999; border-width: 0 0 1px 1px; transform: rotate(-135deg); }

.is-body.open .is-tree { display: block; }

.is-body.open .is-detail { margin-left: 220px; }

.is-body.open .is-detail .is-icon__toggle { margin-left: 2px !important; transform: rotate(45deg) !important; }

.is-msg { position: fixed; bottom: 55px; left: calc(50% + 15px); z-index: 20; min-width: 180px; text-align: center; padding: 11px 20px; background-color: rgba(0, 0, 0, 0.75); border-radius: 22px; text-align: center; color: #fff; font-size: 15px; }

.gnb-fold .is-msg { left: calc(50% + -63px); }

.is-body.open .is-msg { left: calc(50% + 125px); }

.gnb-fold .is-body.open .is-msg { left: calc(50% + 47px); }

/* CONTENTS */
.is-btn__new { width: 100%; height: 51px; background-color: #0b83e6; text-align: center; color: #fff; font-size: 14px; }

.is-btn__new:before { display: inline-block; margin: -2px 6px 0 0; vertical-align: middle; background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: 0px -258px; width: 24px; height: 24px; content: ""; }

.is-tree__box { position: absolute; overflow: auto; top: 51px; right: 0; bottom: 105px; left: 0; }

.is-tree__box:not(first-child) { margin-top: 16px; }

.is-tree__box .is-tree__box-in { position: absolute; top: 0; min-width: 100%; }

.is-tree__box .is-tree__empty { position: relative; top: 50%; right: 0; left: 0; margin-top: -35px; text-align: center; color: #999; line-height: 1.7; }

.is-tree__folder { position: relative; padding: 0 3px 0 38px; line-height: 40px; white-space: nowrap; }

.is-tree__folder .item_icon { position: relative; vertical-align: middle; line-height: 40px; }

.is-tree__folder .item_icon .is-icon { margin-top: -6px; }

.is-tree__folder .item_text { min-width: calc(100% - 28px); padding: 0 2px 2px; text-align: left; vertical-align: middle; color: #111; line-height: 38px; white-space: nowrap; }

.is-tree__folder .is-text__warning { position: relative; color: #f43142; line-height: 1.5; }

.is-tree__folder .is-form__text-2 { position: relative; width: auto; width: 138px; padding: 0 2px; }

.is-tree__folder .is-btn__folder-open { overflow: hidden; position: absolute; top: 0; left: 3px; width: 35px; height: 40px; line-height: 200px; }

.is-tree__folder .is-btn__folder-open .is-icon__view-toggle { position: absolute; top: 50%; left: 50%; height: 8px; width: 8px; margin: -4px 0 0 -7px; border: 1px solid #aaa; border-width: 0 1px 1px 0; transform: rotate(-45deg); }

.is-tree__sub { display: none; padding-left: 23px; }

.is-tree__sub .is-tree__sub { padding-left: 10px; }

.is-tree__list { overflow: hidden; }

.is-tree__list li.open { overflow: visible; }

.is-tree__list li.open > .is-tree__sub { display: block; }

.is-tree__list li.open > .is-tree__folder .is-icon__view-toggle { margin: -6px 0 0 -4px; border-color: #444; transform: rotate(45deg); }

.is-tree__list li.selected > .is-tree__folder { background-color: #fafafa; }

.is-tree__list li.selected > .is-tree__folder:before { position: absolute; top: 0; right: -999px; bottom: 0; left: -999px; background-color: #fafafa; content: ""; }

.is-tree__list li.selected > .is-tree__folder > .item_text { position: relative; color: #0b83e6; font-weight: bold; }

.is-tree__list li.selected > .is-tree__folder .is-icon__view-toggle { border-color: #444; }

.is-tree__trash { position: absolute; bottom: 0; width: 100%; background-color: #fafafa; border-top: 1px solid #eee; }

.is-tree__trash > button { overflow: hidden; width: 100%; height: 52px; padding: 0 16px 0 14px; text-align: left; color: #111; line-height: 52px; }

.is-tree__trash > button:before { display: inline-block; margin-right: 8px; vertical-align: middle; content: ""; background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -238px -258px; width: 24px; height: 24px; }

.is-tree__trash > button > i { float: right; vertical-align: middle; color: #333; font-weight: bold; line-height: 52px; font-style: normal; }

.is-tree__trash > button > i.text_default { color: #999; font-weight: normal; }

.is-tree__trash > button.is-btn__restore:before { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -204px -258px; width: 24px; height: 24px; }

.is-path { position: relative; padding: 0 128px 0 39px; }

.is-path .set_location { display: flex; }

.is-path .set_location .location_item { position: relative; display: block; flex: 0 1 auto; min-width: 33px; padding-right: 5px; }

.is-path .set_location .location_item .item_text { display: block; width: 100%; height: 52px; padding: 0 7px; text-align: left; color: #333; font-size: 15px; line-height: 52px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.is-path .set_location .location_item .item_text:hover { text-decoration: underline; }

.is-path .set_location .location_item:after { position: absolute; top: 50%; right: 0; width: 0; height: 0; border-top: 4px solid transparent; border-left: 5px solid #ccc; border-bottom: 4px solid transparent; transform: translateY(-50%); content: ""; }

.is-path .set_location .location_item:last-child .item_text { position: relative; padding-right: 20px; color: #0b83e6; }

.is-path .set_location .location_item:last-child .item_text:hover { text-decoration: none; }

.is-path .set_location .location_item:last-child .item_text:after { position: absolute; top: 50%; right: 5px; width: 0; height: 0; border-right: 4px solid transparent; border-top: 5px solid #0b83e6; border-left: 4px solid transparent; transform: translateY(-50%); content: ""; }

.is-path .set_location .location_item:last-child:after { content: none; }

.is-path .set_location .location_item .is-layer__menu { top: 43px; left: 50%; width: 142px; max-height: 237px !important; margin-left: -71px; }

.is-path .set_location .location_item .is-layer__menu li { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.is-path .set_location .location_item .is-layer__menu li.selected { color: #f43142; }

.is-path .set_location .location_item.active .item_text:after { border-top: none; border-bottom: 5px solid #0b83e6; }

.is-path .set_location .location_item.active .is-layer__menu { display: block; }

.is-path .set_control { position: absolute; top: 50%; right: 10px; margin-top: -20px; }

.is-control { display: table; width: 100%; padding: 10px 20px 18px; box-sizing: border-box; }

.is-control .is-control__arrange { display: table-cell; width: 263px; vertical-align: bottom; white-space: nowrap; }

.is-control .is-control__arrange:not(:last-child) { padding-right: 10px; }

.is-control .is-control__arrange .is-btn__icon { margin-left: 6px; padding: 1px; }

.is-control .is-control__arrange .is-btn__icon.active { padding: 0; background-color: #fafafa; border: solid 1px #dddddd; }

.is-control .is-control__search { display: table-cell; width: 160px; vertical-align: bottom; }

.is-control .is-control__edit { position: relative; display: table-cell; padding-top: 4px; text-align: right; vertical-align: bottom; white-space: nowrap; }

.is-control .is-control__edit > button { width: 68px; height: 62px; background-color: #fff; border: 1px solid #ddd; color: #333; font-size: 12px; }

.is-control .is-control__edit > button:not(:first-child) { margin-left: -4px; }

.is-control .is-control__edit > button:before { display: block; margin: auto; content: ""; }

.is-control .is-control__edit > button:focus { position: relative; z-index: 2; }

.is-control .is-control__edit > button.active { background-color: #fafafa; }

.is-control .is-control__edit .is-layer__list, .is-control .is-control__edit .is-path .set_location .location_item .is-layer__menu, .is-path .set_location .location_item .is-control .is-control__edit .is-layer__menu { margin-top: 8px; }

.is-layer__list, .is-path .set_location .location_item .is-layer__menu { display: none; overflow-y: auto; position: absolute; z-index: 9; min-width: 142px; max-height: 310px; padding: 10px 0; background-color: #fff; border: 1px solid #bbb; }

.is-layer__list.active, .is-path .set_location .location_item .active.is-layer__menu { display: block; }

.is-layer__list li, .is-path .set_location .location_item .is-layer__menu li { padding: 7px 11px 8px; text-align: left; color: #666; cursor: pointer; }

.is-layer__list li:hover, .is-path .set_location .location_item .is-layer__menu li:hover { background-color: #fafafa; color: #111; }

.is-view__drag { position: absolute; top: 0; right: 0; bottom: 0; left: -1px; z-index: 11; border: solid 1px #0b83e6; background-color: rgba(255, 255, 255, 0.8); }

.is-view__drag:before { position: absolute; top: 0; right: 0; bottom: 0; left: 0; border: solid 10px rgba(11, 131, 230, 0.35); content: ""; }

.is-view__drag .drag_info { position: absolute; top: 50%; left: 50%; margin: -22px 0 0 -180px; padding: 10px 40px 9px; background-color: #0b83e6; border-radius: 28px; text-align: center; color: #fff; font-size: 15px; }

.is-view__drag .drag_info:before { display: inline-block; margin-right: 6px; vertical-align: -6px; content: ""; background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -258px -204px; width: 24px; height: 24px; }

.is-view__drag .drag_info > em { font-weight: bold; }

.is-view__drag-file { position: absolute; z-index: 12; width: 93px; text-align: center; }

.is-view__drag-file .file_img { position: relative; display: block; height: 70px; margin: 0 auto; background-color: #fff; border: solid 5px #ffffff; box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4); }

.is-view__drag-file .file_img > img { position: absolute; top: 0; right: 0; bottom: 0; left: 0; max-width: 100%; max-height: 100%; margin: auto; border: solid 1px #ddd; }

.is-view__drag-file .file_text { display: inline-block; max-width: 100%; margin: 14px auto 0; padding: 0 5px; background-color: #2776dd; border-radius: 4px; color: #fff; line-height: 1.4; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.is-view__reload { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 11; background-color: rgba(255, 255, 255, 0.8); }

.is-view__reload .reload_info { position: absolute; top: 200px; left: 50%; min-width: 168px; min-height: 168px; margin-left: -84px; padding: 46px 25px; background-color: #fff; border: solid 1px #ddd; text-align: center; }

.is-view__reload .reload_info .loading { margin-bottom: -20px; }

.is-view.is-view__img { zoom: 1; }

.is-view.is-view__img:after { display: block; content: ""; clear: both; }

.is-view.is-view__img .is-view__top { height: 44px; margin-bottom: 20px; padding: 2px 10px; background-color: #fafafa; }

.is-view.is-view__img .is-view__body { padding: 0 13px; zoom: 1; }

.is-view.is-view__img .is-view__body:after { display: block; content: ""; clear: both; }

.is-view.is-view__img .is-file { position: relative; float: left; width: 174px; height: 228px; padding: 0 7px 6px; }

.is-view.is-view__img .is-file.upper:hover { text-decoration: none; }

.is-view.is-view__img .is-file.upper .item_title { display: block; padding: 0; text-align: left; }

.is-view.is-view__img .is-file .is-layer__list, .is-view.is-view__img .is-file .is-path .set_location .location_item .is-layer__menu, .is-path .set_location .location_item .is-view.is-view__img .is-file .is-layer__menu { top: 80px; left: 80px; }

.is-view.is-view__img .item_img { position: relative; display: block; width: 160px; height: 160px; margin-bottom: 10px; background-color: #fff; border: solid 1px #ddd; }

.is-view.is-view__img .item_img > input[type="checkbox"] { position: absolute; }

.is-view.is-view__img .item_img > input[type="checkbox"]:checked + label:before { position: absolute; width: 100%; height: 100%; background-color: rgba(244, 49, 66, 0.1); border: 1px solid #f43142; content: ""; }

.is-view.is-view__img .item_img > input[type="checkbox"]:checked + label:after { background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -292px 0px; width: 22px; height: 22px; position: absolute; right: 0; bottom: 0; width: 22px; height: 22px; background-color: #f43142; content: ""; }

.is-view.is-view__img .item_img > label { position: absolute; top: -1px; right: -1px; bottom: -1px; left: -1px; cursor: pointer; }

.is-view.is-view__img .item_img .is-icon__list2-folder, .is-view.is-view__img .item_img .is-icon__list2-folder-upper { position: absolute; top: 50%; left: 50%; margin: -40px 0 0 -40px; }

.is-view.is-view__img .item_img .is-icon__list2-loading { position: absolute; top: 50%; left: 50%; margin: -27px 0 0 -27px; }

.is-view.is-view__img .item_img > img { position: absolute; top: 0; right: 0; bottom: 0; left: 0; margin: auto; max-width: 100%; max-height: 100%; }

.is-view.is-view__img .is-btn__icon { position: relative; z-index: 2; float: left; }

.is-view.is-view__img .item_title { position: relative; height: 28px; padding-left: 26px; color: #111; }

.is-view.is-view__img .item_title.type_file { padding-right: 30px; }

.is-view.is-view__img .item_title.type_file > button { width: calc(100% + 30px); }

.is-view.is-view__img .item_title.type_file .file_text { position: absolute; top: 4px; right: 0; width: 28px; }

.is-view.is-view__img .item_title > button { display: block; width: 100%; padding-top: 2px; text-align: left; color: #111; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.is-view.is-view__img .item_title .is-text__warning { position: absolute; z-index: 2; top: 32px; left: 0; right: 0; background-color: #fff; color: #f43142; font-size: 12px; }

.is-view.is-view__img .item_title .is-text__em { background-color: rgba(248, 185, 7, 0.15); line-height: 18px; }

.is-view.is-view__list .td_upper { padding: 10px 0 0; }

.is-view.is-view__list .td_upper > button { display: block; width: 100%; height: 48px; padding-left: 20px; text-align: left; line-height: 48px; }

.is-view.is-view__list .td_upper > button .is-icon { vertical-align: -6px; }

.is-view.is-view__list .td_upper > button .upper_text { margin-left: 12px; }

.is-view.is-view__list .item_title { position: relative; color: #111; }

.is-view.is-view__list .item_title.type_file { padding-right: 40px; }

.is-view.is-view__list .item_title.type_file > button { width: calc(100% + 40px); }

.is-view.is-view__list .item_title.type_file .file_text { position: absolute; top: 0; right: 12px; width: 36px; }

.is-view.is-view__list .item_title > button { display: block; width: 100%; height: 48px; margin: -9px 0; text-align: left; vertical-align: middle; border: 1px solid transparent; color: #111; line-height: 30px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.is-view.is-view__list .item_title .is-form__text-3 { width: 100%; width: calc(100% - 12px); margin-top: -2px; }

.is-view.is-view__list .item_title .is-text__warning { margin: 4px 0 -8px; padding-left: 8px; color: #f43142; font-size: 12px; line-height: 18px; }

.is-view.is-view__list .item_title .is-text__em { background-color: rgba(248, 185, 7, 0.15); line-height: 18px; }

.is-view.is-view__list .item_title .is-layer__list, .is-view.is-view__list .item_title .is-path .set_location .location_item .is-layer__menu, .is-path .set_location .location_item .is-view.is-view__list .item_title .is-layer__menu { top: 17px; right: 100px; }

.is-view { position: relative; }

.is-view .is-file__nodata { padding: 80px 30px; text-align: center; color: #999; line-height: 1.7; }

.is-view .is-file__nodata:before { clear: both; display: block; margin: 25px auto; content: ""; background-image: url("/img/selleroffice/sprites/sp_img_storage.png"); background-position: -90px -168px; width: 54px; height: 54px; }

.is-view .is-file__nodata .data_policy { margin-top: 40px; color: #333; }

.is-view .is-file__nodata .data_policy .link_info { margin-top: 2px; padding: 2px; vertical-align: 0; color: #0b83e6; }

.is-view .is-file__nodata .data_policy .link_info:after { display: inline-block; height: 7px; width: 7px; margin-left: 2px; border: 1px solid #0b83e6; border-width: 0 1px 1px 0; vertical-align: 2px; transform: rotate(-45deg); content: ""; }

.is-view.is-view__list .is-file__nodata { padding: 80px 30px; }

.is-view.is-view__list .is-file__nodata:before { margin-top: 0; }

.is-view .is-file__nodata-search { padding: 54px 30px 74px; text-align: center; color: #999; }

.is-view .is-file__nodata-search > strong { color: #666; }

.is-view.is-view__list .is-file__nodata-search { padding-top: 64px; }

/* 윈도우 팝업 */
.is-popup { background-color: #fff; height: 100vh; }

.is-popup-body { height: calc(100vh - 80px); overflow: hidden; overflow-y: auto; }

.is-popup-bottom { border-top: 1px solid #ddd; padding: 16px 40px 15px; text-align: center; zoom: 1; }

.is-popup-bottom button { margin: 0 5px; }

.is-storage .dialog-body { padding: 0; }

.is-storage .is-path, .is-storage .is-control { padding-left: 24px; padding-right: 24px; }

.is-storage .is-control__search { width: auto; }

.is-storage .is-view__top { padding-left: 10px; padding-right: 10px; }

.is-storage .is-view__img .is-view__body { padding: 0 22px; }

.is-storage .is-view__img .is-view__body .is-file:nth-of-type(4n) .is-layer__list, .is-storage .is-view__img .is-view__body .is-file:nth-of-type(4n) .is-path .set_location .location_item .is-layer__menu, .is-path .set_location .location_item .is-storage .is-view__img .is-view__body .is-file:nth-of-type(4n) .is-layer__menu { left: 50px; }

.is-storage .is-msg { left: 50%; margin-left: -90px; }

.is-storage.dialog .is-control__search, .is-storage.dialog .c-dropdown .btn, .is-storage.dialog .c-input .input-text .inp { width: 120px; }

/* 레이어팝업 */
.is-info__title { display: block; margin-bottom: 20px; color: #111; font-size: 16px; }

.is-info__list li { color: #333; font-size: 15px; }

.is-info__list li + li { margin-top: 8px; }

.is-info__list li > em { margin-right: 6px; color: #999; }

.c-progress + .is-table__fixed { margin-top: 17px; }

.is-file__check { margin: 20px 0 0 -10px; }

.is-file__amount { margin-top: 30px; }

.is-file__amount .amount_text > strong { color: #111; }

.is-file__amount .amount_text > i { font-style: normal; }

.is-file__guide { margin-top: 2px; color: #999; }

.is-text__info { margin-bottom: 35px; text-align: center; color: #111; font-weight: bold; font-size: 16px; }

.is-move__box { overflow-y: auto; height: 320px; margin: 16px 0 20px; padding: 9px 0; background-color: #fff; border: 1px solid #eee; }

.is-move__list { overflow: hidden; }

.is-move__list li.open > .is-move__sub { display: block; }

.is-move__list li.open > .is-move__folder .is-icon__view-toggle { top: 21px; border-color: #444; transform: rotate(-135deg); }

.is-move__list .is-move__sub { display: none; padding-left: 20px; }

.is-move__folder { position: relative; display: block; line-height: 46px; }

.is-move__folder .is-btn__folder-open { position: absolute; top: 0; left: 0; z-index: 2; width: 42px; height: 100%; }

.is-move__folder .is-btn__folder-open .is-icon__view-toggle { position: absolute; top: 16px; left: 50%; height: 8px; width: 8px; margin-left: -1px; border: 1px solid #aaa; border-width: 0 1px 1px 0; transform: rotate(45deg); }

.is-move__folder > .is-form__radio-text { position: relative; display: block; padding-left: 46px; color: #111; }

.is-move__folder > .is-form__radio-text .inp[type="radio"] { position: absolute; top: 0; left: -50%; width: 200%; height: 100%; opacity: 0; cursor: pointer; }

.is-move__folder > .is-form__radio-text .inp[type="radio"] + .lab { position: relative; display: block; margin: 0 12px 0 36px; cursor: pointer; }

.is-move__folder > .is-form__radio-text .inp[type="radio"] + .lab .is-icon { position: absolute; top: 11px; left: -36px; }

.is-move__folder > .is-form__radio-text .inp[type="radio"] + .lab .folder_text { position: relative; z-index: 2; display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.is-move__folder > .is-form__radio-text .inp[type="radio"] + .lab .is-form__text-3 { position: relative; z-index: 2; }

.is-move__folder > .is-form__radio-text .inp[type="radio"]:checked + .lab { position: relative; background-color: #fafafa; }

.is-move__folder > .is-form__radio-text .inp[type="radio"]:checked + .lab .folder_text, .is-move__folder > .is-form__radio-text .inp[type="radio"]:checked + .lab .is-form__text-3 { color: #0b83e6; font-weight: bold; }

.is-move__folder > .is-form__radio-text .inp[type="radio"]:checked + .lab:before { position: absolute; top: 0; right: -999px; bottom: 0; left: -999px; background-color: #fafafa; content: ""; }

.is-move__folder .is-text__warning { position: relative; z-index: 2; margin: -3px 0 0 8px; color: #f43142; font-size: 12px; line-height: 18px; }

/* alert */
.is-file__list { text-align: center; color: #f43142; font-size: 15px; }

.is-file__list li + li { margin-top: 4px; }

.is-alert { margin-top: 20px; text-align: center; }

.is-alert .sub_text { display: block; margin-top: 8px; font-size: 15px; line-height: 24px; letter-spacing: -0.44px; color: #666; font-weight: normal; }

.is-alert [class^="check-style"] { margin-left: -9px; }

.is-alert [class^="check-style"]:not(:first-child) { margin-top: 20px; }

.is-alert [class^="check-style"] .lab { color: #333; font-size: 15px; letter-spacing: 0; }

/** 해상도 별 스타일 **/
@media (max-width: 1350px) { .is-detail .is-control { padding-right: 10px; padding-left: 10px; }
  .is-detail .is-control .is-control__arrange:not(:last-child) { width: 234px; padding-right: 4px; }
  .is-detail .is-control .c-dropdown .btn { width: 138px; }
  .is-detail .is-control .c-input.w-200 .inp { width: 165px; } }
