@charset "UTF-8";
@import "../selleroffice/mixins"; //믹스인모음
@import "../selleroffice/helpers/variables"; //변수모음
@import "../selleroffice/helpers/reset"; //스타일 초기화

@import "../selleroffice/sprites/sp_img_storage.scss";

/* COMMON */
// form
.is-form__text-2,
.is-form__text-3 {
    // input-text
    width: 100%;
    height: 26px;
    background-color: transparent;
    border: 1px solid $color-secondary;
    color: #111;
    &.warning {
        border: solid 1px $color-brand;
    }
}
.is-form__text-3 {
    height: 28px;
    @include text-ellipsis();
}
// btn
.is-btn__icon {
    background-color: transparent;
    border: none;
}
// icon
.is-icon {
    overflow: hidden;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    color: transparent;
    line-height: 200px;
    font-size: 0;
}
.is-icon__refresh {
    margin: 9px;
    @include sprite($sp_img_storage_icon_refresh);
}
.is-btn__file-upload:before {
    @include sprite($sp_img_storage_icon_file-upload);
}
.is-btn__file-download:before {
    @include sprite($sp_img_storage_icon_file-download);
}
.is-btn__file-folder-new:before {
    @include sprite($sp_img_storage_icon_file-folder-new);
}
.is-btn__file-move:before {
    @include sprite($sp_img_storage_icon_file_move);
}
.is-btn__file-delete:before {
    @include sprite($sp_img_storage_icon_file-trash);
}
.is-btn__file-trash-all:before {
    @include sprite($sp_img_storage_icon_file-trash-all);
}
.is-btn__file-restore:before {
    @include sprite($sp_img_storage_icon_file-restore);
}
.is-icon__view-img {
    margin: 1px;
    @include sprite($sp_img_storage_icon_view_img);
}
.is-icon__view-list {
    position: relative;
    width: 38px;
    height: 38px;
    &:before {
        display: block;
        width: 14px;
        height: 13px;
        margin: 12px 12px 12px;
        border-top: 1px solid #333;
        border-bottom: 1px solid #333;
        content: "";
    }
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 1px;
        margin: -1px 0 0 -7px;
        background-color: #333;
        content: "";
    }
}
.is-icon__search {
    margin: 8px;
    @include sprite($sp_img_storage_icon_search);
}
.is-icon__shortcut {
    @include sprite($sp_img_storage_icon_shortcut);
    .is-btn__icon.active & {
        @include sprite($sp_img_storage_icon_shortcut_on);
    }
}
.is-icon__folder {
    @include sprite($sp_img_storage_icon_folder);
}
.is-icon__list-folder {
    @include sprite($sp_img_storage_icon_list-folder);
}
.is-icon__list-folder-upper {
    @include sprite($sp_img_storage_icon_list_folder_upper);
}
.is-icon__list-img {
    @include sprite($sp_img_storage_icon_list_img);
}
.is-icon__list-file {
    @include sprite($sp_img_storage_icon_list_file);
}
.is-icon__list2-folder {
    @include sprite($sp_img_storage_icon_list2-folder);
}
.is-icon__list2-folder-upper {
    @include sprite($sp_img_storage_icon_list2_folder-upper);
}
.is-icon__list2-file {
    @include sprite($sp_img_storage_icon_list2_file);
}
.is-icon__list2-loading {
    @include sprite($sp_img_storage_icon_list_loading);
}

// table
.is-table {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
    table-layout: fixed;
    .td_center {
        text-align: center;
    }
    thead {
        th,
        td {
            padding: 0 4px;
            height: 44px;
            background-color: #fafafa;
            text-align: left;
            font-weight: normal;
            font-size: 14px;
            color: #333;
        }
    }
    tbody {
        tr {
            &:hover,
            &.selected {
                background-color: #fafafa;
            }
            &.tr_info {
                background-color: inherit;
                td {
                    padding: 0;
                }
            }
            th,
            td {
                padding: 9px 4px;
                font-size: 14px;
                color: #111;
                text-align: left;
                vertical-align: top;
                line-height: 30px;
            }
            &:first-of-type {
                th,
                td {
                    position: relative;
                    padding-top: 14px;
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 10px;
                        background-color: #fff;
                        content: "";
                    }
                }
            }
        }
    }
    thead th.td_check .check-style__5 {
        margin-top: 0;
    }
    th,
    td {
        &.td_check {
            padding-left: 11px;
            .check-style__5 {
                margin: -10px 0 -5px;
            }
        }
        &.td_title {
            padding-left: 20px;
        }
        &.td_date {
            color: #999;
        }
        &.td_info {
            color: #666;
        }
        &.td_info2 {
            color: #111;
        }
        &.td_state {
            .state_waiting,
            .state_skip {
                color: #999;
            }
            .state_success {
                color: $color-secondary;
            }
            .state_warning {
                color: $color-brand;
            }
        }
        &.td_file {
            padding-left: 20px;
            .file_set {
                display: block;
                max-width: 320px;
                @include text-ellipsis();
            }
            .is-icon {
                margin: -3px 12px 0 0;
            }
            .is-icon__list-lower {
                position: relative;
                margin-right: 4px;
                width: 24px;
                height: 24px;
                &:before {
                    position: absolute;
                    top: 7px;
                    left: 7px;
                    width: 10px;
                    height: 10px;
                    border: 1px solid #ccc;
                    border-width: 0 0 1px 1px;
                    content: "";
                }
            }
        }
        .is-btn__icon {
            vertical-align: 2px;
        }
    }
}
.is-table__fixed {
    overflow: hidden;
    position: relative;
    height: 320px;
    padding-top: 44px;
    background-color: #fafafa;
    border: 1px solid #eee;
    zoom: 1;
    .in_scroll {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        background-color: #fff;
        zoom: 1;
    }
    .is-table {
        border-collapse: separate;
        border-spacing: 0;
        th,
        td {
            border: none;
        }
        thead th {
            padding: 0px;
            height: 0px;
            background-color: #fafafa;
            border: none;
            &.td_file {
                padding-left: 20px;
            }
            .th-text {
                position: absolute;
                top: 0;
                width: inherit;
                padding: 11px 0 12px;
                background-color: #fafafa;
            }
        }
    }
}

/* LAYOUT */
.is-header {
    display: table;
    width: 100%;
    padding: 17px 24px 20px;
    border: 1px solid #e0e0e0;
    background: #fff;
    box-sizing: border-box;
    .is-header__title {
        display: table-cell;
        vertical-align: middle;
        .title {
            color: #333;
            font-size: 24px;
        }
        .text_sub {
            margin-top: 3px;
            color: #666;
            font-size: 13px;
        }
        .link_info {
            margin-left: 6px;
            padding: 0 2px;
            vertical-align: 0;
            color: #0b83e6;
            font-size: 13px;
            &:after {
                display: inline-block;
                height: 6px;
                width: 6px;
                border: 1px solid #0b83e6;
                border-width: 0 1px 1px 0;
                vertical-align: 2px;
                transform: rotate(-45deg);
                content: "";
            }
        }
    }
    .is-header__amount {
        display: table-cell;
        width: 200px;
        text-align: center;
        vertical-align: middle;
        .amount_text {
            color: #333;
            > i {
                color: $color-secondary;
                font-style: normal;
            }
        }
        .amount_bar {
            margin-top: 10px;
            background-color: #dddddd;
            border: solid 1px #d8d8d8;
            border-radius: 3.5px;
            .bar_rate {
                overflow: hidden;
                display: block;
                height: 5px;
                margin: -1px 0;
                background-color: $color-secondary;
                border-radius: 3.5px;
                color: transparent;
                font-size: 0;
                line-height: 999px;
            }
        }
    }
}
.is-body {
    position: relative;
    border: 1px solid #e0e0e0;
    background: #fff;
    @include clearfix();
    .is-header + & {
        border-top-color: #eee;
        margin-top: -1px;
    }
    .is-tree {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
        width: 220px;
        min-height: 980px;
        padding-bottom: 52px;
        vertical-align: top;
        border-right: 1px solid #eee;
    }
    .is-detail {
        position: relative;
        min-height: 980px;
        .is-btn__toggle {
            position: absolute;
            z-index: 2;
            top: -1px;
            left: -1px;
            width: 26px;
            height: 52px;
            background-color: #fff;
            border: 1px solid #eee;
            .is-icon__toggle {
                height: 8px;
                width: 8px;
                margin: -4px 0 0 -2px;
                border: 1px solid #999;
                border-width: 0 0 1px 1px;
                transform: rotate(-135deg);
            }
        }
    }
    &.open {
        .is-tree {
            display: block;
        }
        .is-detail {
            margin-left: 220px;
            .is-icon__toggle {
                margin-left: 2px !important;
                transform: rotate(45deg) !important;
            }
        }
    }
}
.is-msg {
    position: fixed;
    bottom: 55px;
    left: calc(50% + 15px);
    z-index: 20;
    min-width: 180px;
    text-align: center;
    padding: 11px 20px;
    background-color: rgba($color: #000, $alpha: 0.75);
    border-radius: 22px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    .gnb-fold & {
        left: calc(50% + -63px);
    }
    .is-body.open & {
        left: calc(50% + 125px);
    }
    .gnb-fold .is-body.open & {
        left: calc(50% + 47px);
    }
}

/* CONTENTS */
// 폴더 트리
.is-btn__new {
    width: 100%;
    height: 51px;
    background-color: $color-secondary;
    text-align: center;
    color: #fff;
    font-size: 14px;
    &:before {
        display: inline-block;
        margin: -2px 6px 0 0;
        vertical-align: middle;
        @include sprite($sp_img_storage_icon_folder_new);
        content: "";
    }
}
.is-tree__box {
    position: absolute;
    overflow: auto;
    top: 51px;
    right: 0;
    bottom: 105px;
    left: 0;
    &:not(first-child) {
        margin-top: 16px;
    }
    .is-tree__box-in {
        position: absolute;
        top: 0;
        min-width: 100%;
    }
    .is-tree__empty {
        position: relative;
        top: 50%;
        right: 0;
        left: 0;
        margin-top: -35px;
        text-align: center;
        color: #999;
        line-height: 1.7;
    }
}
.is-tree__folder {
    position: relative;
    padding: 0 3px 0 38px;
    line-height: 40px;
    white-space: nowrap;
    .item_icon {
        position: relative;
        vertical-align: middle;
        line-height: 40px;
        .is-icon {
            margin-top: -6px;
        }
    }
    .item_text {
        min-width: calc(100% - 28px);
        padding: 0 2px 2px;
        text-align: left;
        vertical-align: middle;
        color: #111;
        line-height: 38px;
        white-space: nowrap;
    }
    .is-text__warning {
        position: relative;
        color: $color-brand;
        line-height: 1.5;
    }
    .is-form__text-2 {
        position: relative;
        width: auto;
        width: 138px;
        padding: 0 2px;
    }
    .is-btn__folder-open {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 3px;
        width: 35px;
        height: 40px;
        line-height: 200px;
        .is-icon__view-toggle {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 8px;
            width: 8px;
            margin: -4px 0 0 -7px;
            border: 1px solid #aaa;
            border-width: 0 1px 1px 0;
            transform: rotate(-45deg);
        }
    }
}
.is-tree__sub {
    display: none;
    padding-left: 23px;
    & & {
        padding-left: 10px;
    }
}
.is-tree__list {
    overflow: hidden;
    li.open {
        overflow: visible;
        > .is-tree__sub {
            display: block;
        }
        > .is-tree__folder {
            .is-icon__view-toggle {
                margin: -6px 0 0 -4px;
                border-color: #444;
                transform: rotate(45deg);
            }
        }
    }
    li.selected {
        > .is-tree__folder {
            background-color: #fafafa;
            &:before {
                position: absolute;
                top: 0;
                right: -999px;
                bottom: 0;
                left: -999px;
                background-color: #fafafa;
                content: "";
            }
            > .item_text {
                position: relative;
                color: $color-secondary;
                font-weight: bold;
            }
            .is-icon__view-toggle {
                border-color: #444;
            }
        }
    }
}
.is-tree__trash {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fafafa;
    border-top: 1px solid #eee;
    > button {
        overflow: hidden;
        width: 100%;
        height: 52px;
        padding: 0 16px 0 14px;
        text-align: left;
        color: #111;
        line-height: 52px;
        &:before {
            display: inline-block;
            margin-right: 8px;
            vertical-align: middle;
            content: "";
            @include sprite($sp_img_storage_icon_trash);
        }
        > i {
            float: right;
            vertical-align: middle;
            color: #333;
            font-weight: bold;
            line-height: 52px;
            font-style: normal;
            &.text_default {
                color: #999;
                font-weight: normal;
            }
        }
        &.is-btn__restore:before {
            @include sprite($sp_img_storage_icon_restore);
        }
    }
}

// 폴더-파일 상세
.is-path {
    position: relative;
    padding: 0 128px 0 39px;
    .set_location {
        display: flex;
        .location_item {
            position: relative;
            display: block;
            flex: 0 1 auto;
            min-width: 33px;
            padding-right: 5px;
            .item_text {
                display: block;
                width: 100%;
                height: 52px;
                padding: 0 7px;
                text-align: left;
                color: #333;
                font-size: 15px;
                line-height: 52px;
                @include text-ellipsis();
                &:hover {
                    text-decoration: underline;
                }
            }
            &:after {
                position: absolute;
                top: 50%;
                right: 0;
                width: 0;
                height: 0;
                border-top: 4px solid transparent;
                border-left: 5px solid #ccc;
                border-bottom: 4px solid transparent;
                transform: translateY(-50%);
                content: "";
            }
            &:last-child {
                .item_text {
                    position: relative;
                    padding-right: 20px;
                    color: $color-secondary;
                    &:hover {
                        text-decoration: none;
                    }
                    &:after {
                        position: absolute;
                        top: 50%;
                        right: 5px;
                        width: 0;
                        height: 0;
                        border-right: 4px solid transparent;
                        border-top: 5px solid $color-secondary;
                        border-left: 4px solid transparent;
                        transform: translateY(-50%);
                        content: "";
                    }
                }
                &:after {
                    content: none;
                }
            }
            .is-layer__menu {
                @extend .is-layer__list;
                top: 43px;
                left: 50%;
                width: 142px;
                max-height: 237px !important;
                margin-left: -71px;
                li {
                    @include text-ellipsis();
                    &.selected {
                        color: $color-brand;
                    }
                }
            }
            &.active {
                .item_text:after {
                    border-top: none;
                    border-bottom: 5px solid $color-secondary;
                }
                .is-layer__menu {
                    display: block;
                }
            }
        }
    }
    .set_control {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -20px;
    }
}
.is-control {
    display: table;
    width: 100%;
    padding: 10px 20px 18px;
    box-sizing: border-box;
    .is-control__arrange {
        display: table-cell;
        width: 263px;
        vertical-align: bottom;
        white-space: nowrap;
        &:not(:last-child) {
            padding-right: 10px;
        }
        .is-btn__icon {
            margin-left: 6px;
            padding: 1px;
            &.active {
                padding: 0;
                background-color: #fafafa;
                border: solid 1px #dddddd;
            }
        }
    }
    .is-control__search {
        display: table-cell;
        width: 160px;
        vertical-align: bottom;
    }
    .is-control__edit {
        position: relative;
        display: table-cell;
        padding-top: 4px;
        text-align: right;
        vertical-align: bottom;
        white-space: nowrap;
        > button {
            width: 68px;
            height: 62px;
            background-color: #fff;
            border: 1px solid #ddd;
            color: #333;
            font-size: 12px;
            &:not(:first-child) {
                margin-left: -4px;
            }
            &:before {
                display: block;
                margin: auto;
                content: "";
            }
            &:focus {
                position: relative;
                z-index: 2;
            }
            &.active {
                background-color: #fafafa;
            }
        }
        .is-layer__list {
            margin-top: 8px;
        }
    }
}
.is-layer__list {
    display: none;
    overflow-y: auto;
    position: absolute;
    z-index: 9;
    min-width: 142px;
    max-height: 310px;
    padding: 10px 0;
    background-color: #fff;
    border: 1px solid #bbb;
    &.active {
        display: block;
    }
    li {
        padding: 7px 11px 8px;
        text-align: left;
        color: #666;
        cursor: pointer;
        &:hover {
            background-color: #fafafa;
            color: #111;
        }
    }
}
// drag&drop
.is-view__drag {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -1px;
    z-index: 11;
    border: solid 1px #0b83e6;
    background-color: rgba(255, 255, 255, 0.8);
    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: solid 10px rgba(11, 131, 230, 0.35);
        content: "";
    }
    .drag_info {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -22px 0 0 -180px;
        padding: 10px 40px 9px;
        background-color: #0b83e6;
        border-radius: 28px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        &:before {
            display: inline-block;
            margin-right: 6px;
            vertical-align: -6px;
            content: "";
            @include sprite($sp_img_storage_icon_file_upload2);
        }
        > em {
            font-weight: bold;
        }
    }
}
.is-view__drag-file {
    position: absolute;
    z-index: 12;
    width: 93px;
    text-align: center;
    .file_img {
        position: relative;
        display: block;
        height: 70px;
        margin: 0 auto;
        background-color: #fff;
        border: solid 5px #ffffff;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
        > img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            border: solid 1px #ddd;
        }
    }
    .file_text {
        display: inline-block;
        max-width: 100%;
        margin: 14px auto 0;
        padding: 0 5px;
        background-color: #2776dd;
        border-radius: 4px;
        color: #fff;
        line-height: 1.4;
        @include text-ellipsis();
    }
}
// 새로고침중
.is-view__reload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    background-color: rgba(255, 255, 255, 0.8);
    .reload_info {
        position: absolute;
        top: 200px;
        left: 50%;
        min-width: 168px;
        min-height: 168px;
        margin-left: -84px;
        padding: 46px 25px;
        background-color: #fff;
        border: solid 1px #ddd;
        text-align: center;
        .loading {
            margin-bottom: -20px;
        }
    }
}

// 리스트-아이콘보기
.is-view.is-view__img {
    @include clearfix();
    .is-view__top {
        height: 44px;
        margin-bottom: 20px;
        padding: 2px 10px;
        background-color: #fafafa;
    }
    .is-view__body {
        padding: 0 13px;
        @include clearfix();
    }
    .is-file {
        position: relative;
        float: left;
        width: 174px;
        height: 228px;
        padding: 0 7px 6px;
        &.upper {
            &:hover {
                text-decoration: none;
            }
            .item_title {
                display: block;
                padding: 0;
                text-align: left;
            }
        }
        .is-layer__list {
            top: 80px;
            left: 80px;
        }
    }
    .item_img {
        position: relative;
        display: block;
        width: 160px;
        height: 160px;
        margin-bottom: 10px;
        background-color: #fff;
        border: solid 1px #ddd;
        > input[type="checkbox"] {
            position: absolute;
            &:checked + label {
                &:before {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: $color-brand, $alpha: 0.1);
                    border: 1px solid $color-brand;
                    content: "";
                }
                &:after {
                    @include sprite($sp_img_storage_icon_check);
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 22px;
                    height: 22px;
                    background-color: $color-brand;
                    content: "";
                }
            }
        }
        > label {
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            cursor: pointer;
        }
        .is-icon__list2-folder,
        .is-icon__list2-folder-upper {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -40px 0 0 -40px;
        }
        .is-icon__list2-loading {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -27px 0 0 -27px;
        }
        > img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .is-btn__icon {
        position: relative;
        z-index: 2;
        float: left;
    }
    .item_title {
        position: relative;
        height: 28px;
        padding-left: 26px;
        color: #111;
        &.type_file {
            padding-right: 30px;
            > button {
                width: calc(100% + 30px);
            }
            .file_text {
                position: absolute;
                top: 4px;
                right: 0;
                width: 28px;
            }
        }
        > button {
            display: block;
            width: 100%;
            padding-top: 2px;
            text-align: left;
            color: #111;
            @include text-ellipsis();
        }
        .is-text__warning {
            position: absolute;
            z-index: 2;
            top: 32px;
            left: 0;
            right: 0;
            background-color: #fff;
            color: $color-brand;
            font-size: 12px;
        }
        .is-text__em {
            background-color: rgba($color: #f8b907, $alpha: 0.15);
            line-height: 18px;
        }
    }
}
// 리스트-자세히보기
.is-view.is-view__list {
    .td_upper {
        padding: 10px 0 0;
        & > button {
            display: block;
            width: 100%;
            height: 48px;
            padding-left: 20px;
            text-align: left;
            line-height: 48px;
            .is-icon {
                vertical-align: -6px;
            }
            .upper_text {
                margin-left: 12px;
            }
        }
    }
    .item_title {
        position: relative;
        color: #111;
        &.type_file {
            padding-right: 40px;
            > button {
                width: calc(100% + 40px);
            }
            .file_text {
                position: absolute;
                top: 0;
                right: 12px;
                width: 36px;
            }
        }
        > button {
            display: block;
            width: 100%;
            height: 48px;
            margin: -9px 0;
            text-align: left;
            vertical-align: middle;
            border: 1px solid transparent;
            color: #111;
            line-height: 30px;
            @include text-ellipsis();
        }
        .is-form__text-3 {
            width: 100%;
            width: calc(100% - 12px);
            margin-top: -2px;
        }
        .is-text__warning {
            margin: 4px 0 -8px;
            padding-left: 8px;
            color: $color-brand;
            font-size: 12px;
            line-height: 18px;
        }
        .is-text__em {
            background-color: rgba($color: #f8b907, $alpha: 0.15);
            line-height: 18px;
        }
        .is-layer__list {
            top: 17px;
            right: 100px;
        }
    }
}
// 리스트-내용 없음
.is-view {
    position: relative;
    .is-file__nodata {
        padding: 80px 30px;
        text-align: center;
        color: #999;
        line-height: 1.7;
        &:before {
            clear: both;
            display: block;
            margin: 25px auto;
            content: "";
            @include sprite($sp_img_storage_icon_list_nodata);
        }
        .data_policy {
            margin-top: 40px;
            color: #333;
            .link_info {
                margin-top: 2px;
                padding: 2px;
                vertical-align: 0;
                color: #0b83e6;
                &:after {
                    display: inline-block;
                    height: 7px;
                    width: 7px;
                    margin-left: 2px;
                    border: 1px solid #0b83e6;
                    border-width: 0 1px 1px 0;
                    vertical-align: 2px;
                    transform: rotate(-45deg);
                    content: "";
                }
            }
        }
    }
    &.is-view__list .is-file__nodata {
        padding: 80px 30px;
        &:before {
            margin-top: 0;
        }
    }
    .is-file__nodata-search {
        padding: 54px 30px 74px;
        text-align: center;
        color: #999;
        > strong {
            color: #666;
        }
    }
    &.is-view__list .is-file__nodata-search {
        padding-top: 64px;
    }
}
/* 윈도우 팝업 */
.is-popup {
    background-color: #fff;
    height: 100vh;
}
.is-popup-body {
    height: calc(100vh - 80px);
    overflow: hidden;
    overflow-y: auto;
}
.is-popup-bottom {
    border-top: 1px solid #ddd;
    padding: 16px 40px 15px;
    text-align: center;
    zoom: 1;
    button {
        margin: 0 5px;
    }
}
// 11Editor용 이미지스토리지 팝업
.is-storage {
    .dialog-body {
        padding: 0;
    }
    .is-path,
    .is-control {
        padding-left: 24px;
        padding-right: 24px;
    }
    .is-control__search {
        width: auto;
    }
    .is-view__top {
        padding-left: 10px;
        padding-right: 10px;
    }
    .is-view__img .is-view__body {
        padding: 0 22px;
        .is-file:nth-of-type(4n) .is-layer__list {
            left: 50px;
        }
    }
    .is-msg {
        left: 50%;
        margin-left: -90px;
    }
    &.dialog {
        .is-control__search,
        .c-dropdown .btn,
        .c-input .input-text .inp {
            width: 120px;
        }
    }
}

/* 레이어팝업 */
// 관리> 폴더/파일 정보
.is-info__title {
    display: block;
    margin-bottom: 20px;
    color: #111;
    font-size: 16px;
}
.is-info__list {
    li {
        color: #333;
        font-size: 15px;
        & + li {
            margin-top: 8px;
        }
        > em {
            margin-right: 6px;
            color: #999;
        }
    }
}
// 폴더/파일 업로드
.c-progress + .is-table__fixed {
    margin-top: 17px;
}
.is-file__check {
    margin: 20px 0 0 -10px;
}
.is-file__amount {
    margin-top: 30px;
    .amount_text {
        > strong {
            color: #111;
        }
        > i {
            font-style: normal;
        }
    }
}
.is-file__guide {
    margin-top: 2px;
    color: #999;
}
// 삭제> 삭제 실패
.is-text__info {
    margin-bottom: 35px;
    text-align: center;
    color: #111;
    font-weight: bold;
    font-size: 16px;
}
// 위치 선택
.is-move__box {
    overflow-y: auto;
    height: 320px;
    margin: 16px 0 20px;
    padding: 9px 0;
    background-color: #fff;
    border: 1px solid #eee;
}
.is-move__list {
    overflow: hidden;
    li.open {
        > .is-move__sub {
            display: block;
        }
        > .is-move__folder {
            .is-icon__view-toggle {
                top: 21px;
                border-color: #444;
                transform: rotate(-135deg);
            }
        }
    }
    .is-move__sub {
        display: none;
        padding-left: 20px;
    }
}
.is-move__folder {
    position: relative;
    display: block;
    line-height: 46px;
    .is-btn__folder-open {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 42px;
        height: 100%;
        .is-icon__view-toggle {
            position: absolute;
            top: 16px;
            left: 50%;
            height: 8px;
            width: 8px;
            margin-left: -1px;
            border: 1px solid #aaa;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }
    }
    > .is-form__radio-text {
        position: relative;
        display: block;
        padding-left: 46px;
        color: #111;
        .inp[type="radio"] {
            position: absolute;
            top: 0;
            left: -50%;
            width: 200%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
            & + .lab {
                position: relative;
                display: block;
                margin: 0 12px 0 36px;
                cursor: pointer;
                .is-icon {
                    position: absolute;
                    top: 11px;
                    left: -36px;
                }
                .folder_text {
                    position: relative;
                    z-index: 2;
                    display: block;
                    @include text-ellipsis();
                }
                .is-form__text-3 {
                    position: relative;
                    z-index: 2;
                }
            }
        }
        .inp[type="radio"]:checked {
            & + .lab {
                position: relative;
                background-color: #fafafa;
                .folder_text,
                .is-form__text-3 {
                    color: $color-secondary;
                    font-weight: bold;
                }
                &:before {
                    position: absolute;
                    top: 0;
                    right: -999px;
                    bottom: 0;
                    left: -999px;
                    background-color: #fafafa;
                    content: "";
                }
            }
        }
    }
    .is-text__warning {
        position: relative;
        z-index: 2;
        margin: -3px 0 0 8px;
        color: #f43142;
        font-size: 12px;
        line-height: 18px;
    }
}

/* alert */
.is-file__list {
    text-align: center;
    color: $color-brand;
    font-size: 15px;
    li + li {
        margin-top: 4px;
    }
}
.is-alert {
    margin-top: 20px;
    text-align: center;
    .sub_text {
        display: block;
        margin-top: 8px;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.44px;
        color: #666;
        font-weight: normal;
    }
    [class^="check-style"] {
        margin-left: -9px;
        &:not(:first-child) {
            margin-top: 20px;
        }
        .lab {
            color: #333;
            font-size: 15px;
            letter-spacing: 0;
        }
    }
}

/** 해상도 별 스타일 **/
@media (max-width: 1350px) {
    .is-detail .is-control {
        padding-right: 10px;
        padding-left: 10px;
        .is-control__arrange:not(:last-child) {
            width: 234px;
            padding-right: 4px;
        }
        .c-dropdown .btn {
            width: 138px;
        }
        .c-input.w-200 .inp {
            width: 165px;
        }
    }
}
