// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sp_img_storage_icon_check: (292px, 0px, -292px, 0px, 22px, 22px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-check', );
$sp_img_storage_icon_file_download: (200px, 168px, -200px, -168px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-file-download', );
$sp_img_storage_icon_file_folder_new: (258px, 0px, -258px, 0px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-file-folder-new', );
$sp_img_storage_icon_file_move: (258px, 34px, -258px, -34px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-file-move', );
$sp_img_storage_icon_file_restore: (258px, 68px, -258px, -68px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-file-restore', );
$sp_img_storage_icon_file_trash_all: (258px, 102px, -258px, -102px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-file-trash-all', );
$sp_img_storage_icon_file_trash: (258px, 136px, -258px, -136px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-file-trash', );
$sp_img_storage_icon_file_upload: (258px, 170px, -258px, -170px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-file-upload', );
$sp_img_storage_icon_file_upload2: (258px, 204px, -258px, -204px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-file-upload2', );
$sp_img_storage_icon_folder_new: (0px, 258px, 0px, -258px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-folder-new', );
$sp_img_storage_icon_folder: (34px, 258px, -34px, -258px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-folder', );
$sp_img_storage_icon_list_file: (68px, 258px, -68px, -258px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-list-file', );
$sp_img_storage_icon_list_folder_upper: (102px, 258px, -102px, -258px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-list-folder-upper', );
$sp_img_storage_icon_list_folder: (136px, 258px, -136px, -258px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-list-folder', );
$sp_img_storage_icon_list_img: (170px, 258px, -170px, -258px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-list-img', );
$sp_img_storage_icon_list_loading: (168px, 90px, -168px, -90px, 54px, 54px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-list-loading', );
$sp_img_storage_icon_list_nodata: (90px, 168px, -90px, -168px, 54px, 54px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-list-nodata', );
$sp_img_storage_icon_list2_file: (0px, 0px, 0px, 0px, 158px, 158px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-list2-file', );
$sp_img_storage_icon_list2_folder_upper: (168px, 0px, -168px, 0px, 80px, 80px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-list2-folder-upper', );
$sp_img_storage_icon_list2_folder: (0px, 168px, 0px, -168px, 80px, 80px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-list2-folder', );
$sp_img_storage_icon_refresh: (292px, 32px, -292px, -32px, 22px, 22px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-refresh', );
$sp_img_storage_icon_restore: (204px, 258px, -204px, -258px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-restore', );
$sp_img_storage_icon_search: (292px, 64px, -292px, -64px, 22px, 22px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-search', );
$sp_img_storage_icon_shortcut_on: (292px, 96px, -292px, -96px, 22px, 22px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-shortcut-on', );
$sp_img_storage_icon_shortcut: (292px, 128px, -292px, -128px, 22px, 22px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-shortcut', );
$sp_img_storage_icon_trash: (238px, 258px, -238px, -258px, 24px, 24px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-trash', );
$sp_img_storage_icon_view_img: (154px, 168px, -154px, -168px, 36px, 36px, 314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', 'sp_img_storage_icon-view-img', );
$spritesheet_sprites: ($sp_img_storage_icon_check, $sp_img_storage_icon_file_download, $sp_img_storage_icon_file_folder_new, $sp_img_storage_icon_file_move, $sp_img_storage_icon_file_restore, $sp_img_storage_icon_file_trash_all, $sp_img_storage_icon_file_trash, $sp_img_storage_icon_file_upload, $sp_img_storage_icon_file_upload2, $sp_img_storage_icon_folder_new, $sp_img_storage_icon_folder, $sp_img_storage_icon_list_file, $sp_img_storage_icon_list_folder_upper, $sp_img_storage_icon_list_folder, $sp_img_storage_icon_list_img, $sp_img_storage_icon_list_loading, $sp_img_storage_icon_list_nodata, $sp_img_storage_icon_list2_file, $sp_img_storage_icon_list2_folder_upper, $sp_img_storage_icon_list2_folder, $sp_img_storage_icon_refresh, $sp_img_storage_icon_restore, $sp_img_storage_icon_search, $sp_img_storage_icon_shortcut_on, $sp_img_storage_icon_shortcut, $sp_img_storage_icon_trash, $sp_img_storage_icon_view_img, );
$spritesheet: (314px, 282px, '/img/selleroffice/sprites/sp_img_storage.png', $spritesheet_sprites, );


